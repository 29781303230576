import { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress.tsx";
export function ProgressLoader() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const cycleProgress = () => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1));
    };

    const timer = setInterval(cycleProgress, 50); // Обновляем прогресс каждые 50 мс
    return () => clearInterval(timer);
  }, []);

  return <Progress value={progress} className="w-full " />;
}
