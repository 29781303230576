import { useState, useCallback } from "react";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useMe } from "@/hooks/useMe.tsx";

interface FormData {
  name: string;
  role: string;
  business: string;
  experience: string;
  sources: string;
}

interface SurveyQuestion {
  question: string;
  answer: string;
}

interface SurveyPayload {
  user: string;
  questions: SurveyQuestion[];
  timestamp: string;
}

interface SurveyResponse {
  success: boolean;
  message: string;
}

export const useSaveSurvey = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const formatQuestions = (formData: FormData): SurveyQuestion[] => {
    return [
      {
        question: "What is your name?",
        answer: formData.name,
      },
      {
        question: "What is your role?",
        answer: formData.role,
      },
      {
        question: "What is your business type?",
        answer: formData.business,
      },
      {
        question: "What is your experience?",
        answer: formData.experience,
      },
      {
        question: "How did you learn about Next Big Niche?",
        answer: formData.sources,
      },
    ];
  };

  const setUsername = useCallback(async (name: string): Promise<void> => {
    try {
      await AxiosInstance.post("/set-username", {
        username: name,
      });
      // console.log("Username set successfully");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to set username.";
      console.log(errorMessage);
    }
  }, []);

  const saveSurvey = useCallback(
    async (formData: FormData, userEmail: string): Promise<boolean> => {
      setIsLoading(true);

      const payload: SurveyPayload = {
        user: userEmail,
        questions: formatQuestions(formData),
        timestamp: new Date().toISOString(),
      };

      try {
        const { data } = await AxiosInstance.post<SurveyResponse>(
          "/save-survey",
          payload,
        );

        toast.success(data.message || "Survey submitted successfully!");
        await queryClient.refetchQueries({ queryKey: ["me"] });
      } catch (error) {
        const errorMessage = error.response?.data?.message;

        switch (error.response?.status) {
          case 401:
            toast.error("Please log in to submit the survey.");
            break;
          case 400:
            if (errorMessage === "Email is required") {
              toast.error("Email address is required.");
            } else if (errorMessage === "No answers provided") {
              toast.error("Please answer all questions before submitting.");
            } else {
              toast.error(errorMessage || "Please check your form inputs.");
            }
            break;
          default:
            toast.error(
              error.response?.data?.message ||
                "Failed to submit survey. Please try again.",
            );
        }

        console.error("Survey submission error:", {
          status: error.response?.status,
          message: errorMessage,
          data: error.response?.data,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [queryClient],
  );

  return {
    saveSurvey,
    isLoading,
    setUsername,
  };
};
