import React, { useState } from "react";
import {
  ClientFilters,
  usePaginatedTrends,
} from "@/hooks/usePaginatedTrends/usePaginatedTrends.tsx";

import { Button } from "@/components/ui/button.tsx";
import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";
import { ColumnsSettings } from "@/components/ColumnsSettings/ColumnsSettings.tsx";

import { TablePagination } from "@/components/table/TablePagination.tsx";
import { Cross1Icon, HeartIcon, ResetIcon } from "@radix-ui/react-icons";

import { useUserSettings } from "@/hooks/useUserSettings.tsx";
import useMenuStore from "@/store/useMenuStore.tsx";
import SideMenuButton from "@/components/SideMenuButton.tsx";
import TrendsTable from "@/components/TrendsTable/TrendsTable.tsx";
import { useTrendsPageScroll } from "@/components/TrendsTable/useTrendsPageScroll.tsx";
import { useApplyLastPagination } from "@/hooks/useApplyLastPagination.tsx";

export type SortColumnOption = {
  columnKey: ClientFilters;
  sortValue: 1 | -1 | 0;
};

export const BlackListPage: React.FC = () => {
  const {
    paginatedData = [],
    tableHeadRow,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    fetchTable,
    appliedSort,
    totalPagesCount,
    totalRows,
    currentPaginationPage,
    moveToPaginationPage,
    isPlaceholderData,
    isFetching,
    isLoading,
  } = usePaginatedTrends({ defaultListType: "black" });

  const { scrollAreaRef, scrollToTop } = useTrendsPageScroll();
  const { setMenuShown, menuShown } = useMenuStore();

  useApplyLastPagination({ type: "black", moveToPaginationPage });

  const moveToPaginationPageHandler = (pageNum) => {
    localStorage.setItem(`last-page-black`, pageNum);
    moveToPaginationPage(pageNum);
    setTimeout(() => scrollToTop(), 100);
  };

  const addSort = ({ sort }: { sort: SortColumnOption[] }) => {
    addSortHandler({ newSort: sort });
  };

  const { addTrendToWatchList, removeTrendsByListName } = useUserSettings();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const addSelectedRowId = (id: number) => {
    if (selectedRows.some((r) => r === id)) {
      return;
    }

    setSelectedRows((prevRows) => [...prevRows, id]);
  };

  const removeSelectedRowId = (id: number) => {
    setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
  };

  return (
    <div className="flex flex-col max-h-screen w-full">
      <div className="flex w-full flex-wrap items-center my-[12px] gap-4 px-[12px]">
        <FilterContainer>
          <SideMenuButton menuShown={menuShown} setMenuShown={setMenuShown} />
        </FilterContainer>

        <FilterContainer>
          <ColumnsSettings
            hiddenRowsIds={hiddenRowsIds}
            hideColumn={hideColumn}
          />
        </FilterContainer>

        <div className={" flex w-fit  gap-[16px] "}>
          <div className={"flex w-fit gap-[16px]"}>
            <FilterContainer>
              <Button
                disabled={selectedRows.length === 0}
                size={"sm"}
                variant="outline"
                onClick={async () => {
                  await removeTrendsByListName({
                    trends_ids: selectedRows,
                    listType: "black",
                  });
                  setSelectedRows([]);
                  void fetchTable();
                }}
              >
                <ResetIcon className={"mr-[6px]"} />
                Remove from blacklist
              </Button>
            </FilterContainer>
            <FilterContainer>
              <Button
                disabled={selectedRows.length === 0}
                size={"sm"}
                variant="outline"
                onClick={async () => {
                  await addTrendToWatchList({ trends_ids: selectedRows });
                  setSelectedRows([]);
                  void fetchTable();
                }}
              >
                <HeartIcon className={"mr-[6px]"} />
                Add to watchlist
              </Button>
            </FilterContainer>
            {selectedRows.length !== 0 && (
              <FilterContainer>
                <Button
                  disabled={selectedRows.length === 0}
                  size={"sm"}
                  variant="outline"
                  onClick={() => {
                    setSelectedRows([]);
                  }}
                >
                  <Cross1Icon className={"mr-[6px]"} />
                  Cancel
                </Button>
              </FilterContainer>
            )}
          </div>
        </div>
      </div>
      <TrendsTable
        isLoading={isLoading}
        ref={scrollAreaRef}
        tableHeadRow={tableHeadRow}
        hiddenRowsIds={hiddenRowsIds}
        appliedSort={appliedSort}
        addSort={addSort}
        paginatedData={paginatedData}
        isFetching={isFetching}
        isPlaceholderData={isPlaceholderData}
        removeSelectedRowId={removeSelectedRowId}
        addSelectedRowId={addSelectedRowId}
        selectedRows={selectedRows}
      />
      <div className="flex py-6 w-screen bg-gray-50 items-center text-black">
        <div className="absolute right-4">
          <span className="text-md font-medium">
            {paginatedData.length} of{" "}
            {typeof totalRows === "number"
              ? totalRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : totalRows}{" "}
          </span>
          <span className="text-sm font-regular">total niches</span>
        </div>
        <div className="absolute flex left-[44%]">
          <TablePagination
            totalPagesCount={totalPagesCount}
            currentPaginationPage={currentPaginationPage}
            moveToPaginationPage={moveToPaginationPageHandler}
          />
        </div>
      </div>
    </div>
  );
};
