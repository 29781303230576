import { QueryClient } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { TrendsPage } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ThemeProvider } from "@/providers/theme-provider.tsx";
import { Toaster } from "@/components/ui/sonner";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { CategoryScale, Chart } from "chart.js";
import { LoginPage } from "@/pages/LoginPage/LoginPage.tsx";
import { UserLayout } from "@/pages/UserLayout/UserLayout.tsx";
import { RegistrationPage } from "@/pages/LoginPage/RegistrationPage.tsx";
import { TestLayout } from "@/pages/TestLayout.tsx";
import { ErrorBoundaryPage } from "@/pages/ErorrBoundaryPage.tsx";
import Smartlook from "smartlook-client";

import * as Sentry from "@sentry/react";
import { BillingPage } from "@/pages/BillingPage/BillingPage.tsx";
import { Settings } from "@/pages/SettingsPage/Settings.tsx";
import { SuccessPaymentPage } from "@/pages/SuccessPaymentPage/SuccessPaymentPage.tsx";
import { IS_PROD } from "@/consts/consts.ts";
import { WatchListPage } from "@/pages/WatchListPage/WatchListPage.tsx";
import { BlackListPage } from "@/pages/BlackListPage/BlackListPage.tsx";
import { PaywallWrapper } from "@/components/PaywallWrapper/PaywalllWrapper.tsx";
import CancelNewsletterPage from "@/pages/CancelNewsletterPage/CancelNewsletterPage.tsx";
import ResetPasswordPage from "@/pages/ResetPasswordPage/ResetPasswordPage.tsx";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
Chart.register(CategoryScale);
const firebaseConfig = {
  apiKey: "AIzaSyArTvKFu36BFfGsQV1nl-6luoS6EuuxGas",
  authDomain: "next-big-niche-5288e.firebaseapp.com",
  projectId: "next-big-niche-5288e",
  storageBucket: "next-big-niche-5288e.firebasestorage.app",
  messagingSenderId: "956747366628",
  appId: "1:956747366628:web:51ff6c4243fdbb9040ecf1",
  measurementId: "G-BFDWB33LB0",
};
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cacheTime: 1000 * 60 * 60 * 12,
      enabled: true,
    },
    mutations: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cacheTime: 1000 * 60 * 60 * 12,
      enabled: true,
    },
  },
});
const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

const router = createBrowserRouter([
  {
    children: [
      {
        path: "/",
        element: <TestLayout />,
        children: [
          {
            path: "registration/:paymentType",
            element: <RegistrationPage />,
          },
          {
            path: "/",
            element: <LoginPage />,
          },
          {
            path: "/cancel-newsletter",
            element: <CancelNewsletterPage />,
          },
          {
            path: "/reset-password/",
            element: <ResetPasswordPage />,
          },
        ],
      },
      {
        path: "*",
        element: <LoginPage />,
      },
      {
        element: <UserLayout />,
        path: "/",
        children: [
          {
            path: "/trends",
            element: (
              <PaywallWrapper>
                <TrendsPage />
              </PaywallWrapper>
            ),
            errorElement: IS_PROD ? <ErrorBoundaryPage /> : null,
          },
          {
            path: "/watchlist",
            element: (
              <PaywallWrapper>
                <WatchListPage />
              </PaywallWrapper>
            ),
            errorElement: IS_PROD ? <ErrorBoundaryPage /> : null,
          },

          {
            path: "/blacklist",
            element: (
              <PaywallWrapper>
                <BlackListPage />
              </PaywallWrapper>
            ),
            errorElement: IS_PROD ? <ErrorBoundaryPage /> : null,
          },
          { path: "/billing", element: <BillingPage /> },
          { path: "/settings", element: <Settings /> },
          { path: "/payment-success", element: <SuccessPaymentPage /> },
        ],
      },
    ],
  },
]);

function App() {
  if (IS_PROD) {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    Smartlook.init("d14294a7952f784958794ad48af5f379cf75bbb6", {
      region: "eu",
    });
    ReactGA.initialize("G-17VWC7KG6P");
    Sentry.init({
      dsn: "https://5d722bca39e42457e2f9a489ea3edd4e@o4507893592752128.ingest.us.sentry.io/4507893602713600",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),

        //    Multiple Sentry Session Replay instances are not supported
        // Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/app\.nextbigniche\.ai\//],
      // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
        <Toaster expand={false} visibleToasts={2} />
      </ThemeProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
