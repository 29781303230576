import { FC, PropsWithChildren, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/ui/table.tsx";
import { formatNumber } from "@/lib/utils.ts";
import CopyToClipboard from "@/components/CopyToClipboard.tsx";
import { makeLinkFromAsin } from "@/helpers/helpers.ts";
import { PopupColumns } from "@/hooks/usePaginatedTrends/types/PopupColumns.ts";

interface TopProductPopover extends PropsWithChildren {
  row: Record<PopupColumns, any>;
  isOnboarding?: boolean;
}
export const TopProductPopover: FC<TopProductPopover> = ({
  children,
  row,
  isOnboarding = false,
}) => {
  const [open, setOpen] = useState(false);

  const asinData = [
    {
      img: row?.img_link_1,
      link: makeLinkFromAsin(row.asin_1),
    },
    {
      img: row?.img_link_2,
      link: makeLinkFromAsin(row.asin_2),
    },
    {
      img: row?.img_link_3,
      link: makeLinkFromAsin(row.asin_3),
    },
  ];

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Popover open={open}>
      <PopoverTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent
        className={`${isOnboarding ? "z-[1001]" : "z-40"} rounded-xl bg-white  w-fit border border-gray-200 shadow-2xl p-[10px]`}
        avoidCollisions
        align={"end"}
        side={"right"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={"!p-2"}>
                <div className={"text-sm font-bold min-w-[100px]"}>
                  Top-3 <br /> products:
                </div>
              </TableCell>

              {asinData.map((l, i) => {
                if (l.img === "None")
                  return (
                    <TableCell className={"!p-2"} key={i}>
                      <img
                        src={"/placeholder-image.png"}
                        className="max-h-[100px] max-w-[100px] rounded"
                        width={100}
                        height={100}
                      />
                    </TableCell>
                  );
                return (
                  <TableCell className={"!p-2"} key={i}>
                    <div className="h-28 w-28 flex justify-center items-center rounded-sm-native bg-inherit transition-all hover:bg-grey-hover">
                      <a href={l.link} target="_blank">
                        <img
                          src={l.img}
                          className="max-h-[100px] max-w-[100px] rounded"
                          width={100}
                          height={100}
                        />
                      </a>
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell className={"!p-2"}>
                <div className={"text-sm min-w-[100px] font-bold"}>ASIN:</div>
              </TableCell>
              {[row.asin_1, row.asin_2, row.asin_3].map((asin, i) => {
                if (!asin)
                  return (
                    <TableCell key={i} className={"!p-2"}>
                      {" "}
                      -{" "}
                    </TableCell>
                  );
                return (
                  <TableCell className={"!p-2 max-w-32"} key={i}>
                    <div className="flex flex-row items-center gap-0.5">
                      <p className="pointer-events-none">{asin}</p>
                      <CopyToClipboard content={asin} />
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell className={"!p-2"}>
                <div className={"text-sm min-w-[100px] font-bold"}>Brand:</div>
              </TableCell>
              {[row.brand_1, row.brand_2, row.brand_3].map((b, i) => {
                if (!b || b === "0")
                  return (
                    <TableCell key={i} className={"!p-2 max-w-32"}>
                      -
                    </TableCell>
                  );
                return (
                  <TableCell key={i} className={"!p-2 max-w-32"}>
                    {b}
                  </TableCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell className={"!p-2"}>
                <div className={"text-sm min-w-[100px] font-bold"}>
                  Click share:
                </div>
              </TableCell>
              {[row.click_share_1, row.click_share_2, row.click_share_3].map(
                (s, i) => {
                  if (!s)
                    return (
                      <TableCell key={i} className={"!p-2"}>
                        -
                      </TableCell>
                    );
                  return (
                    <TableCell key={i} className={"!p-2 max-w-32"}>
                      {formatNumber({ number: s, isPercentage: true })}
                    </TableCell>
                  );
                },
              )}
            </TableRow>

            <TableRow>
              <TableCell className={"!p-2"}>
                <div className={"text-sm min-w-[100px] font-bold"}>
                  Conversion <br /> share:
                </div>
              </TableCell>
              {[row.conv_share_1, row.conv_share_2, row.conv_share_3].map(
                (v, i) => {
                  if (!v)
                    return (
                      <TableCell key={i} className={"!p-2 max-w-32"}>
                        -
                      </TableCell>
                    );
                  return (
                    <TableCell key={i} className={"!p-2 max-w-32"}>
                      {formatNumber({ number: v, isPercentage: true })}
                    </TableCell>
                  );
                },
              )}
            </TableRow>
          </TableBody>
        </Table>
      </PopoverContent>
    </Popover>
  );
};
